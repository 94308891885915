@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap);
html {
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  color: #222;
}

body {
  margin: 0;
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #222;
}

.App {
    margin: 32px;
    width: 600px;
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    position: relative;
}

@media (max-width: 1023px) {
    .App {
        margin: 16px;
        max-width: calc(100vw - 32px);
        min-height: calc(100vh - 32px);
    }
}

.Title {
    font-size: 1.4rem;
    font-weight: bold;
    display: inline-block;
    margin: 0;
    border-bottom: 4px solid #168000;
    line-height: 1.15;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

h1 {
    font-size: 1.96rem;
    margin: 48px 0 24px;
}

h2 {
    font-size: 1.4rem;
    margin: 24px 0;
}

hr {
    border: none;
    border-bottom: 1px solid #DDDDDD;
    margin: 16px -24px;
}

.Card {
    padding: 24px;
    margin: 24px 0;
    border-radius: 8px;
    justify-content: stretch;
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.15);
}

.Coupon {
    display: flex;
    align-items: center;
    transition: box-shadow .2s cubic-bezier(0.4, 0.0, 0.2, 1);
    cursor: pointer;
}

.Coupon:hover, .Coupon:active {
    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.25);
}

.Coupon:hover > .Coupon-Arrow, .Coupon:active > .Coupon-Arrow {
    background: #168000;
    color: #FFFFFF;
}

.Coupon-Title {
    color: #168000;
    font-size: 1.4rem;
}

.Coupon-Shop {
    margin-bottom: 8px;
}

footer {
    color: #777777;
}

.Coupon-Content {
    margin-right: 24px;
    flex-grow: 1;
}

.Coupon-Arrow {
    flex-grow: 0;
    font-size: 1.4rem;
    transition: all .2s cubic-bezier(0.4, 0.0, 0.2, 1);
    color: #777777;
    height: 32px;
    width: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 50%;
}

.Filler {
    flex-grow: 1;
}

footer {
    margin-top: 48px;
}

label {
    display: inline-flex;
    flex-direction: column;
    font-size: .875rem;
    color: #777777;
    flex-grow: 1;
}

form {
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    margin-top: 32px;
}

input[type="text"] {
    border: none;
    border-bottom: 2px solid #CCC;
    display: inline-block;
    box-sizing: content-box;
    padding: 0 0 8px;
    font-size: 1rem;
}

button {
    padding: 10px 12px;
    border: none;
    background: #168000;
    color: #FFFFFF;
    border-radius: 4px;
    cursor: pointer;
    vertical-align: central;
    margin-left: 16px;
    transition: background .2s cubic-bezier(0.4, 0.0, 0.2, 1);
}

button.Compact {
    padding: 6px 12px;
}

button:hover {
    background: #1c9900;
}

p {
    margin: 24px 0;
}

p:last-child {
    margin-bottom: 0;
}

.Coupon-Code > span {
    font-size: 1.4rem;
    color: #168000;
    font-family: monospace;
    -webkit-user-select: all;
       -moz-user-select: all;
        -ms-user-select: all;
            user-select: all;
}

.Alert {
    padding: 16px;
    border-radius: 4px;
}

.Alert.Info {
    background: #caf3ff;
    color: #596a6f;
}

.Alert.Error {
    background: #ffcaca;
    color: #684646;
    margin-top: 16px;
}

.Spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, .8);
    -webkit-animation: fade-spinner .5s cubic-bezier(0.4, 0.0, 0.2, 1);
            animation: fade-spinner .5s cubic-bezier(0.4, 0.0, 0.2, 1);
    display: flex;
    justify-content: center;
    align-items: center;
}

@-webkit-keyframes fade-spinner {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@keyframes fade-spinner {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.Form-Section {
    position: relative;
}

.Spinner-Inner {
    width: 40px;
    height: 40px;
    position: relative;
}

.Spinner-Bounce1, .Spinner-Bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #168000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
            animation: sk-bounce 2.0s infinite ease-in-out;
}

.Spinner-Bounce2 {
    -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
}

main > h1:first-child {
    margin-top: 0;
}

.Animated {
    margin-top: 48px;
    position: relative;
}

@-webkit-keyframes sk-bounce {
    0%, 100% {
        -webkit-transform: scale(0.0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {
    0%, 100% {
        transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
    }
}

.Deactivated {
    padding: 4px 8px;
    display: inline-block;
    border-radius: 4px;
    background: #ffcaca;
    color: #684646;
    font-size: .75rem;
    margin-bottom: 16px;
}

