@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&display=swap');

html {
  font-family: 'Heebo', sans-serif;
  font-size: 16px;
  color: #222;
}

body {
  margin: 0;
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: #222;
}
